import React, { useEffect, useState } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import ICategoryPage from './Models/CategoryPageModel.interface';
import { InitCategoryItems, GetCategoryItems } from '../SearchPage/Search';
import ProductCard from '../ProductCard/ProductCard';
import CardGrid from '../Shared/Grid/CardGrid';
import { theme, styled } from '../Theme';

import LoadMoreContainer from '../Shared/LoadMoreContainer/LoadMoreContainer';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import IProductCardModel from '../ProductCard/Models/ProductCardModel.interface';
import {
  applyEditModeAttr,
  replaceHistoryState,
  updateUrl,
} from '../../Features/Shared/Common/Helpers';
import Grid from '../Shared/Grid/Grid';
import ContentArea from '../Shared/ContentArea/ContentArea';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import ContentContainer from '../Shared/ContentContainer/ContentContainer';
import GridItem from '../Shared/Grid/GridItem';
import ProductList from './ProductList';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import { ListIcon, GridIcon } from '../Shared/Icons';
import { media } from '@glitz/core';
import Translate, { translate } from '../Shared/Common/Translate';
import { StyleOrStyleArray } from '@glitz/type';
import CampaignBanner from '../Shared/Campaign/CampaingBanner';
import ProductVariationModel from './../ProductCard/Models/ProductVariationModel.interface';

enum DisplayMode {
  List = 'List',
  Grid = 'Grid',
}

const TAKE = 12;

function CategoryPage() {
  const {
    translations: {
      'categoryPage/counter': counter,
      'categoryPage/loadMore': loadMore,
      'categoryPage/list': list,
      'categoryPage/grid': grid,
      'categoryPage/numberOfProducts': numberOfProducts,
    },
  } = useAppSettingsData();

  const { authenticated, loyaltyId } = useUserStateData();
  const {
    mainCategoryText,
    pageTitle,
    seoTitle,
    inEditMode,
    mainContent,
    description,
    promotion,
  } = useCurrentPage<ICategoryPage>();
  const [displayMode, setDisplayMode] = useState<string>(DisplayMode.Grid);
  let itemsToTake = TAKE.toString();
  try {
    itemsToTake = window.history.state.count.toString();
  } catch (e) {
    console.log('Exception ', e);
  }

  const result = InitCategoryItems(itemsToTake);
  const loaded =
    (result?.productSearchResultCards?.items?.length /
      result?.productSearchResultCards?.availableItems) *
    100;

  const disableButton =
    result?.productSearchResultCards?.items?.length ===
    result?.productSearchResultCards?.availableItems;

  const fetchMoreItems = async () => {
    const loadedItems = result?.productSearchResultCards.items.length;
    await GetCategoryItems(TAKE, loadedItems, result);
  };

  useEffect(() => {
    if (result?.productSearchResultCards) {
      const url = window.location.pathname;
      replaceHistoryState(seoTitle || pageTitle, url, {
        count: result.productSearchResultCards.items.length,
      });
    }
  }, [result]);

  useEffect(() => {
    if (authenticated) {
      setDisplayMode(DisplayMode.Grid);
    }
  }, [authenticated]);

  return (
    <Root>
      <ContentContainer>
        {loyaltyId && loyaltyId > 0 && promotion ? (
          <CampaignBanner
            heading={promotion.campaignName}
            description={promotion.campaignDescription}
          />
        ) : null}
        <Header>{pageTitle}</Header>
        <CategoryDescription>{description}</CategoryDescription>

        {/* Hiding this for now, might come back later when we get a proper design */}
        {false && (
          <CampaignBanner
            heading={promotion.campaignName}
            description={promotion.promotionDescription}
            image={promotion.promotionBannerImage}
          />
        )}
        {authenticated && (
          <ProductDisplayHeader>
            <Translate
              translation={numberOfProducts}
              values={[
                result.productSearchResultCards?.items?.length.toString(),
              ]}
            />
            <DisplayModeToggle>
              <TextButton
                css={displayMode === DisplayMode.List ? Selected : {}}
                onClick={() => setDisplayMode(DisplayMode.List)}
              >
                <ListIcon css={IconCss} />
                <TextButtonText>{list}</TextButtonText>
              </TextButton>
              <Text>/</Text>
              <TextButton
                css={displayMode === DisplayMode.Grid ? Selected : {}}
                onClick={() => setDisplayMode(DisplayMode.Grid)}
              >
                <GridIcon css={IconCss} />
                <TextButtonText>{grid}</TextButtonText>
              </TextButton>
            </DisplayModeToggle>
          </ProductDisplayHeader>
        )}
        {displayMode === DisplayMode.Grid && (
          <CardGrid maxWidth={theme.screenMaxWidth}>
            {result?.productSearchResultCards?.items &&
              result?.productSearchResultCards?.items.map(
                (product: IProductCardModel) => (
                  <ProductCard key={product.code} item={product} />
                )
              )}
          </CardGrid>
        )}
        {displayMode === DisplayMode.List && (
          <>
            {result?.productSearchResultVariations?.items &&
              result?.productSearchResultVariations?.items.map(
                (product: ProductVariationModel) => (
                  <ProductList key={product.name} item={product} />
                )
              )}
          </>
        )}
      </ContentContainer>
      {result.productSearchResultCards?.availableItems > TAKE && (
        <LoadMoreContainer
          loaded={loaded}
          counterText={translate(counter, [
            result.productSearchResultCards?.items.length,
            result.productSearchResultCards?.availableItems.toString(),
          ]).join('')}
          loadMore={loadMore}
          disableButton={disableButton}
          action={fetchMoreItems}
          totalAmount={result.productSearchResultCards?.availableItems}
          TAKE={TAKE}
        />
      )}

      <ContentContainer>
        <GridItem>
          <BodyText>
            <XhtmlComponent
              {...applyEditModeAttr(inEditMode && 'mainCategoryText')}
              content={mainCategoryText}
            />
          </BodyText>
        </GridItem>
        <Grid
          type={12}
          data-content-area
          {...applyEditModeAttr(inEditMode && 'MainContent')}
        >
          {Array.isArray(mainContent) && (
            <ContentArea childItems={mainContent} />
          )}
        </Grid>
      </ContentContainer>
    </Root>
  );
}

const TextButtonText = styled.p({
  margin: { left: theme.spacing(2) },
});

const Text = styled.p({
  margin: { y: 'auto', left: theme.spacing(2) },
});

const Root = styled.div({ width: '100%', padding: { x: theme.spacing(4) } });
const ProductDisplayHeader = styled.div({
  fontWeight: theme.fontWeight.bold,
  color: theme.black,
  padding: { bottom: theme.spacing(8) },
  display: 'flex',
  justifyContent: 'space-between',
});

const IconCss = {
  margin: { left: theme.spacing(2) },
  width: theme.gamma,
  height: theme.epsilon,
  fill: theme.black,
};

const DisplayModeToggle = styled.div({
  display: 'flex',
});

const TextButton = styled.button({
  font: { weight: theme.fontWeight.normal },
  ':hover': {
    textDecoration: 'underline',
  },
  display: 'flex',
  alignItems: 'center',
  ':focus': {
    outline: { style: 'none' },
  },
});

const Selected: StyleOrStyleArray = {
  textDecoration: 'underline',
  fontWeight: theme.fontWeight.bold,
};

const Header = styled.h1({
  font: { size: theme.nu, weight: theme.fontWeight.bold },
  color: theme.secondaryText,
  margin: { bottom: theme.spacing(2) },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.wider,
  ...media(theme.mediaQuery.mediaMinMedium, { fontSize: theme.iota }),
});

const BodyText = styled.div({
  lineHeight: theme.lineHeight.relaxed,
  letterSpacing: theme.letterSpacing.normalWide,
  margin: { y: theme.spacing(8) },
});

const CategoryDescription = styled.div({
  lineHeight: theme.lineHeight.relaxed,
  letterSpacing: theme.letterSpacing.normalWide,
  margin: { y: theme.spacing(4) },
  width: '100%',
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { y: theme.spacing(8) },
  }),
});

export default CategoryPage;
