import React from 'react';
import { styled, theme } from '../../Theme';
import { media } from '@glitz/core';
import KexLink from '../KexLink/KexLink';

type PropType = {
  heading: string;
  description?: string;
  href?: string;
  image?: string;
  isProductPage?: boolean;
};

function CampaignBanner({
  heading,
  description,
  href,
  image,
  isProductPage,
}: PropType) {
  return (
    <>
      {isProductPage ? (
        <CampaignProduct>
          {image ? <BannerImage src={image}></BannerImage> : null}
          <CampaignText>
            <HeadingProduct>{heading}</HeadingProduct>
            <DescriptionProduct>{description}</DescriptionProduct>
          </CampaignText>
        </CampaignProduct>
      ) : (
        <Campaign>
          {image ? <BannerImage src={image}></BannerImage> : null}
          <CampaignText>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
          </CampaignText>
        </Campaign>
      )}
    </>
  );
}

export default CampaignBanner;
const BannerImage = styled.img({
  minWidth: '10px',
  aspectRatio: '4/3',
  objectFit: 'cover',
  marginRight: theme.spacing(8),
  flex: { grow: 1, shrink: 1, basis: '33%' },
  maxWidth: '33%',
  ...media(theme.mediaQuery.mediaMaxHuge, {
    maxWidth: '50%',
    flex: {
      basis: '50%',
    },
  }),
});

const CampaignText = styled.div({
  display: 'block',
  width: 'auto',
  flex: { grow: 1, shrink: 1, basis: '0' },

  textAlign: 'left',
});

const Campaign = styled.div({
  display: 'inline-flex',
  flexWrap: 'wrap',
  width: '100%',
  backgroundColor: 'rgb(155, 211, 221)',
  padding: {
    xy: theme.spacing(8),
  },
  marginBottom: theme.spacing(6),
  ...media(theme.mediaQuery.mediaMaxLarge, {
    marginBottom: theme.spacing(8),
  }),
});

const Heading = styled.h2({
  font: {
    size: theme.iota,
    weight: theme.fontWeight.bold,
  },
  color: theme.blueDark,
});

const Description = styled.p({
  whiteSpace: 'pre-wrap',
  font: {
    size: theme.gamma,
  },
  textAlign: 'left',
  lineHeight: theme.lineHeight.relaxed,
  marginTop: theme.spacing(2),
});

const CampaignProduct = styled(Campaign, {
  backgroundColor: theme.mediumAqua,
  width: '100%',
  textAlign: 'left',
});

const HeadingProduct = styled.h2({
  font: {
    size: theme.eta,
    weight: theme.fontWeight.bold,
  },
  textAlign: 'center',
  color: theme.blueDark,
  ...media(theme.mediaQuery.mediaMaxMedium, {
    fontSize: theme.epsilon,
  }),
});

const DescriptionProduct = styled.p({
  whiteSpace: 'pre-wrap',

  font: {
    size: theme.delta,
  },
  lineHeight: theme.lineHeight.relaxed,
  textAlign: 'left',
  marginTop: theme.spacing(4),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    fontSize: theme.beta,
    marginTop: theme.spacing(2),
  }),
});
