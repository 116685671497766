import React from 'react';
// import styles from './LoadingDots.module.scss';

type PropType = {
  size?: number;
  color?: string;
};

function LoadingDots({ size = 5, color = 'black' }: PropType) {
  return (
    <div>
      {[1, 2, 3].map(i => (
        <span
          key={i}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            marginRight: i === 3 ? '' : `${size}px`,
          }}
        />
      ))}
    </div>
  );
}

export default LoadingDots;
