import React, { useRef } from 'react';
import loadable from '@loadable/component';
import { IS_SERVER_CONTEXT } from '../Configs/EnvConfig';
import useOnScreen from '../Hooks/useOnScreen';
import LoadingBlock from '../Loading/LoadingBlock/LoadingBlock';

//TODO refactor with correct blocktypes
type PropType = {
  blockType: any;
  isVisible: boolean;
};

const loadableComponents: any = {
  StandardBlock: loadable(() => import('../../StandardBlock/StandardBlock')),
  ItemListingBlock: loadable(() =>
    import('../../Blocks/ItemListingBlock/ItemListingBlock')
  ),
  FormContainerBlock: loadable(() =>
    import('../../Blocks/Forms/FormContainerBlock')
  ),
  ImageLinkListingBlock: loadable(() =>
    import('../../Blocks/ImageLinkListingBlock/ImageLinkListingBlock')
  ),
  ImageLinkBlock: loadable(() => import('../Models/ImageLink/ImageLinkBlock')),
  IconBlock: loadable(() => import('../../Blocks/IconBlock/IconBlock')),
  LinkListBlock: loadable(() =>
    import('../../Blocks/LinkListBlock/LinkListBlock')
  ),
  ImageBlock: loadable(() => import('../../Blocks/ImageBlock/ImageBlock')),
  TextBlock: loadable(() => import('../../Blocks/TextBlock/TextBlock')),
  ProductInformationBlock: loadable(() =>
    import('../../Blocks/ProductInformationBlock/ProductInformationBlock')
  ),
  DocumentListBlock: loadable(() =>
    import('../../Blocks/DocumentListBlock/DocumentListBlock')
  ),
  VideoBlock: loadable(() => import('../../Blocks/VideoBlock/VideoBlock')),
  CategoryBlock: loadable(() =>
    import('../../Blocks/CategoryBlock/CategoryBlock')
  ),
  RowSectionBlock: loadable(() =>
    import('../../Blocks/RowSectionBlock/RowSectionBlock')
  ),
  CountryBlock: loadable(() =>
    import('../../Blocks/CountryBlock/CountryBlock')
  ),
  ErrorBlock: loadable(() => import('../../Blocks/ErrorBlock/ErrorBlock')),
  SectionHeaderBlock: loadable(() =>
    import('../../Blocks/SectionHeaderBlock/SectionHeaderBlock')
  ),
  EventBlock: loadable(() => import('../../Blocks/EventBlock/WebinarBlock')),
  DynamicsFormBlock: loadable(() =>
    import('../../Blocks/DynamicsFormBlock/DynamicsFormBlock')
  ),
};

function VisibleLoadableBlock(props: PropType) {
  const ref = useRef<HTMLSpanElement>(null);
  const onScreen = useOnScreen({ ref });
  const { blockType, isVisible } = props;
  const LoadableComponent = loadableComponents[blockType];

  return LoadableComponent === undefined ? (
    <React.Fragment />
  ) : isVisible || onScreen || IS_SERVER_CONTEXT ? (
    <LoadableComponent fallback={<LoadingBlock />} {...props} />
  ) : (
    <span ref={ref} />
  );
}

export default VisibleLoadableBlock;
